import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/:locale',
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      const supportedLocales =
        process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
      if (!supportedLocales.includes(locale)) {
        return next(process.env.VUE_APP_I18N_LOCALE)
      }
      store.state.locale = locale // watching in App.vue
      return next()
    },
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
      },
      {
        path: 'map-overview',
        name: 'map-overview',
        component: () => import('../views/MapOverviewView.vue')
      },
      {
        path: 'insights',
        name: 'insights',
        component: () => import('../views/InsightsView.vue')
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/DashboardView.vue')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect () {
      return process.env.VUE_APP_I18N_LOCALE
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
