<template>
  <router-view/>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'App',
  watch: {
    '$store.state.locale': function () {
      let locale = this.$store.state.locale
      if (
        !process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',').includes(locale)
      ) {
        locale = process.env.VUE_APP_I18N_LOCALE
      }
      if (this.$i18n.locale !== locale) {
        let path = location.href.replace(location.origin, '')
        path = path.replace(process.env.VUE_APP_PUBLIC_PATH, '')
        path = path.replace(this.$i18n.locale, locale)
        this.$i18n.locale = locale
        this.$router.push({ path: '/' + path })
      }
    }
  },
  mounted () {
    $('body').on('dragstart', 'img', e => e.preventDefault())
  }
}
</script>

<style lang="scss">
@import'~bootstrap/dist/css/bootstrap.min.css';
@import '@/assets/scss/common.scss';
</style>
